var $ = jQuery.noConflict();
jQuery(document).ready(function () {


  $('a[href="#search"]').on('click', function(event) {
    event.preventDefault();
    $('#fullscreensearch').addClass('open');
    $('#fullscreensearch > form > input[type="text"]').focus();
  });
  $('#search-close').on('click', function(event) {
    event.preventDefault();
    $('#fullscreensearch').removeClass('open');
  });


  jQuery('#burger, .overlay, .burgerbottom').click(function () {
    jQuery('#burger').toggleClass('clicked');
    //$('.overlay').toggleClass('show');
    jQuery('.menusmart').toggleClass('show');
  });

  jQuery('.close').click(function () {
    jQuery(this).toggleClass('clicked');
    //$('.overlay').toggleClass('show');
    jQuery('.menusmart').toggleClass('show');
  });
  
  jQuery(window).scroll(function () {
    var windowTop = jQuery(window).scrollTop();
    if (windowTop > 0) {
      jQuery('body').addClass("sticky");
    } else {
      jQuery('body').removeClass("sticky");
    }
  });
  /* MENU SMART */

  jQuery(".menu li.menu-item-has-children .sub-menu").hide();
  jQuery(".menu li.menu-item-has-children").hover(function (e) {
    //e.preventDefault();   
    if (!jQuery(this).hasClass('act')) {

      jQuery('.menusmart li.menu-item-has-children').removeClass('act');

      jQuery('.menusmart li.menu-item-has-children .sub-menu').slideUp(300);

      jQuery(this).addClass("act").find('.sub-menu').slideToggle(300);

    } else {
      jQuery(this).removeClass("act").find('.sub-menu').slideToggle(300);
    }

  });
});